// hooks
import { useSelector } from 'react-redux';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { userInfo } = useSelector((state) => state.authReducer);

  return (
    <Avatar
      src={userInfo?.userDetails?.profileImage}
      alt={userInfo?.userDetails?.firstName}
      color={userInfo?.userDetails?.profileImage ? 'default' : createAvatar(userInfo?.userDetails?.firstName).color}
      {...other}
    >
      {createAvatar(userInfo?.userDetails?.firstName).name}
    </Avatar>
  );
}
