// ** React Imports
import { useEffect } from 'react';
// ** AWS Imports
import Amplify, { Auth } from 'aws-amplify';
// ** Redux Imports
import { useDispatch } from 'react-redux';
import { login, setProfileData, setShopData } from './redux/slices/auth';
// ** Router with 'useRoutes' to define pages
import { useNavigate } from 'react-router';
import Router from './routes';
// ** Theme provider
import ThemeProvider from './theme';
// ** Import progress bar, notify provider and scroll to top component
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
// ** Import 'utils', 'config' and 'env' variables
import { AWS_CONFIG } from './env';
// ** Imports GET, POST, PUT and DELETE utils fnc
import ROUTES_PATH from './routes/paths';
import { getShopDataByUserId, getUserByEmail } from './utils/auth';

export default function App() {
  const navigate = useNavigate();

  Amplify.configure({
    Auth: {
      mandatorySignId: true,
      region: AWS_CONFIG.cognito.REGION,
      userPoolId: AWS_CONFIG.cognito.USER_POOL_ID,
      userPoolWebClientId: AWS_CONFIG.cognito.APP_CLIENT_ID,
    },
  });

  const dispatch = useDispatch();

  const handleAutoLogin = async () => {
    try {
      const cognitoSession = await Auth.currentSession();
      const congitoUserInfo = await Auth.currentUserInfo();
      const accessToken = cognitoSession.getIdToken().getJwtToken();

      // find user by access token
      const userRes = await getUserByEmail(congitoUserInfo?.attributes?.email, accessToken);

      if (!userRes) {
        throw new Error('Invalid token or User not found');
      }

      dispatch(login(accessToken));
      dispatch(setProfileData(userRes));

      // find shop using user id
      const shopRes = await getShopDataByUserId({
        userId: userRes?.userDetails?.userId,
        accessToken,
      });

      dispatch(setShopData(shopRes?.[0] || null)); /** Set shop to Redux */

      if (!shopRes?.[0] && !window.location.pathname.includes(ROUTES_PATH.auth.verify)) {
        navigate(ROUTES_PATH.company.details);
      }
    } catch (e) {}
  };

  useEffect(() => {
    handleAutoLogin();
  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        {/* <ThemeSettings> */}
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
        {/* </ThemeSettings> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
