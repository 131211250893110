/* eslint-disable spaced-comment */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    GET_ORDERS_BY_SHOP_ID: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders/byshop',
    UPDATE_ORDER_BY_ORDER_ID: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders/status',
    GET_SHOP_CATEGORY: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/category',
    SAVE_NEW_SHOP: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops',
    SAVE_NEW_SHOP_ADDRESS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/address',
    // UPDATE_SHOP_ADDRESS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/address',
    UPDATE_SHOP_ADDRESS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/address',
    SAVE_NEW_SHOP_TIMING: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shoptime',
    GET_SHOP_TIMINGS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shoptime',
    UPDATE_SHOP_TIMING: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shoptime',
    // GET_SHOP_DASHBOARD: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/dashboard',
    GET_SHOPS_BY_USERID: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/byuser',
    GET_ADDRESS_BY_SHOPID: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/address',
    UPDATE_SHOP_DETAILS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops',
    GET_PRODUCT_CATEGORY: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/category',
    TIMESLOT: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/timeslot/shop',
    ALL_TIMESLOT: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/timeslot/shop/all',
    PRODUCTS: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products',
    SHOP_GET_PRODUCTS_BY_SHOP_ID: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/byshop2',
    ACTIVATE_SHOP_FOR_USER: `https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/updtshopsetup`,
    GET_PRODUCT_TEMPLATES:
        'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/productTemplate',
    ADD_PRODUCT_FROM_TEMPLATE: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/addlist',
    GET_TEMPLATE_FROM_ID: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/productTemplate',
    //OFFERS
    OFFERS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shopoffer',
    //MYCUSTOMER
    GET_MY_CUSTOMERS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/relationship/myRetailers',
    GET_CUSTOMER: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/getStorePrivacy',
    ADD_CUSTOMER: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/relationship/addRetailer',
    DELETE_CUSTOMER: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/relationship/deleteRetailer',
    GET_SHOP_DASHBOARD: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders/summary',
    GET_SHOP_ORDERS_FILTERED: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/filtered',
    GET_ALL_SHOP_DATA: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/zip',
    GET_SHOP_DATA_BY_ID: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops',
    GET_SHOP_TIMINGS_BY_ID: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shoptime',
    GET_SLOTS_BY_SHOP_AND_DATE: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/timeslot/order',
    UPLOAD_DOCUMENT: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/upload/verify',
    ADD_SHOP_CUSTOMER: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/relationship/addRetailer',
    GET_ALL_RETAILERS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/relationship/myRetailers',
    ADD_UPDATE_PRODUCT: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products',
    GET_ALL_CUSTOMERS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shopCustomer/getAll',
    GET_CUSTOMER_ORDER_BY_DURATION:
        'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders/bycustomer',
    CHECK_IF_CUSTOMER_ELIGIBLE: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shopCustomer/check',
    GET_ALL_SHOP_CUSTOMERS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shopCustomer/getAll',
    GET_ORDERS_BY_CUSTOMER: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders/bycustomer',
    GET_BAHIKHATA_CUSTOMERS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shopCustomer/khata',
    GET_BAHIKHATA_TRANSACTIONS:
        'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shopCustomer/khata/trans',
    UPDATE_CUSTOMER_KHATA_ELIGIBILITY:
        'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shopCustomer/khata/updt',
    ADD_NEW_PAYMENT: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shopCustomer/khata/add',
    GET_INVENTORY_SUMMARY: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/summary',
    PRODUCT_SKU: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/sku/generate',
    GET_PRODUCT_SIZES: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/sizeInfo',
    // Products
    DELETE_PRODUCT_BY_ID: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/deleteProduct',
};
