/* eslint-disable spaced-comment */

export default {
    //Offers
    GET_OFFERS_BY_SHOP_ID: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shopoffer',
    GET_OFFERS_BY_ZIP_CODE: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shopofferbyzip',

    //Products
    // GET_PRODUCTS_BY_SHOP_ID: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/byshop',
    GET_PRODUCTS_BY_SHOP_ID_NEW: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/byshop2',
    GET_WHOLESALE_PRODUCTS_BY_SHOPID: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/wholeSale',
    GET_PRODUCT_BY_PRODUCT_ID: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products',

    //Rewards
    GET_REWARDS_BY_USER_ID: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/rwdsummary',
    GET_REWARD_SUMMARY_BY_USER_SHOP_ID: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/rwdsummaryforshop/',
    GET_REWARD_HISTORY_BY_SHOP_ID_USER_ID: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/rwdhist',

    //Shops
    GET_SHOPS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops',
    GET_SHOP_OFFERS_BY_SHOP_ID: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shopoffer',
    GET_SHOPS_BY_ZIP_CODE: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/zip',
    GET_WHOLESALE_SHOPS_BY_ZIP_CODE :'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shoplist',
    GET_SHOP_TIMINGS: 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/shoptime',
    GET_SHOP_TIMESLOT: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/timeslot/order',
    GET_OFFERS_CAROUSEL : 'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/carousel',
    //Orders
    GET_ORDERS_BY_USER_ID: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders/byuser',
    CANCEL_ORDER_BY_ORDER_ID: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders/status',
    CREATE_NEW_ORDER: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders',
    GET_ORDER_BY_ORDER_ID: 'https://1n03krwv99.execute-api.ap-south-1.amazonaws.com/dynamo-prod/orders',

    //User
    UPDATE_ZIP_CODE: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/updtzip',
    APPLY_PROMO_CODE:  'https://okus2j9qe3.execute-api.ap-south-1.amazonaws.com/shop-prod/shops/applyPromo',

     //Templates
 GET_SHOP_PRODUCT_TEMPLATES: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/productTemplate',
 CREATE_PRODUCTS_FROM_TEMPLATE:   'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/addlist'
}