import { createSlice } from '@reduxjs/toolkit';

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    loadingAllPlans: true,
    planList: [],
    loadingAllPlansHistory: true,
    plansHistoryList: [],
  },
  reducers: {
    setPlanList: (state, action) => {
      state.loadingAllPlans = false;
      state.planList = action.payload || [];
    },
    setPlansHistoryList: (state, action) => {
      state.loadingAllPlansHistory = false;
      state.plansHistoryList = action.payload || [];
    },
    setPlansHistoryLoading: (state, action) => {
      state.loadingAllPlansHistory = action.payload || false;
    },
  },
});

export const { setPlanList, setPlansHistoryList, setPlansHistoryLoading } = subscriptionSlice.actions;
