import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Box, Container, Link, Stack, Typography } from '@mui/material';
// components
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {/* {!isHome ? ( */}
      <MainFooter />
      {/* ) : (
        <Box
          sx={{
            py: 1,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
          }}
        >
          <Typography color={'primary'} fontSize={'14px'}>
            @ 2024 AtomTrip.com
          </Typography>
          , All rights reserved.
        </Box>
      )} */}
    </Stack>
  );
}
