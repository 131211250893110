function path(root, sublink) {
  return `${root}${sublink || ''}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ------------------ Don't remove lines before this line -----------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    payment: path(ROOTS_DASHBOARD, '/payment'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    shopAddress: path(ROOTS_DASHBOARD, '/e-commerce/shop/address'),
    shopDetails: path(ROOTS_DASHBOARD, '/e-commerce/shop/details'),
    shopTimings: path(ROOTS_DASHBOARD, '/e-commerce/shop/timings'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    template: path(ROOTS_DASHBOARD, '/e-commerce/product/template'),
    createFromTemplate: (id) => path(ROOTS_DASHBOARD, `/e-commerce/product/template/new/${id}`),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    product: {
      view: (id) => path(ROOTS_DASHBOARD, `/e-commerce/product/view/${id}`),
    },
    edit: (id) => path(ROOTS_DASHBOARD, `/e-commerce/product/edit/${id}`),
    travelNew: path(ROOTS_DASHBOARD, '/e-commerce/travel/product/new'),
    travelView: (name) => path(ROOTS_DASHBOARD, `/e-commerce/travel/product/${name}`),
    travelEdit: (id) => path(ROOTS_DASHBOARD, `/e-commerce/product/travel/edit/${id}`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },

  booking: {
    root: path(ROOTS_DASHBOARD, '/travel'),
    new: path(ROOTS_DASHBOARD, '/travel/product/new'),
    list: path(ROOTS_DASHBOARD, '/travel/list'),
    view: (name) => path(ROOTS_DASHBOARD, `/travel/product/${name}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/travel/product/edit/${id}`),
    copy: (id2) => path(ROOTS_DASHBOARD, `/travel/product/copy/${id2}`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  offers: {
    root: path(ROOTS_DASHBOARD, '/offers'),
    list: path(ROOTS_DASHBOARD, '/offers/list'),
    new: path(ROOTS_DASHBOARD, '/offers/new'),
    edit: path(ROOTS_DASHBOARD, `/offers/edit`),
  },
  customers: {
    list: path(ROOTS_DASHBOARD, '/my-customers'),
    orders: (id) => path(ROOTS_DASHBOARD, `/my-customers/orders/${id}`),
    bahikhataList: path(ROOTS_DASHBOARD, '/my-bahikhata'),
    transactions: (id) => path(ROOTS_DASHBOARD, `/my-bahikhata/transactions/${id}`),
  },
};

// ** All paths for auth, dashboard etc.
const ROUTES_PATH = {
  auth: {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify'),
  },
  dashboard: {
    home: '/',
    root: ROOTS_DASHBOARD,
    about: path('/about'),
    contact: path('/contact'),
    privacyPolicy: path('/privacy-policy'),
    termsOfService: path('/terms-of-service'),
  },
  company: {
    root: '/company',
    details: path('/company', '/details'),
    address: path('/company', '/address'),
    settings: path('/company', '/settings'),
  },
  plan: {
    root: '/plan',
    list: path('/plan', '/list'),
    history: path('/plan', '/history'),
  },
  quotation: {
    root: '/quotation',
    list: path('/quotation', '/list'),
    create: path('/quotation', '/create'),
    edit: (id) => path('/quotation', `/edit/${id}`),
    copy: (id) => path('/quotation', `/copy/${id}`),
    view: (id) => path('/quotation', `/view/${id}`),
  },
  customer: {
    root: '/customer',
    list: path('/customer', '/list'),
  },
  profile: {
    root: '/profile',
    account: path('/profile', '/account'),
  },
  payment: {
    root: '/payment',
  },
};

export default ROUTES_PATH;

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
