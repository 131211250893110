export default {
  // https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod
  GET_ALL_PACKAGES: `https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travel/package/all`,
  GET_PACKAGE_BY_ID: `https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travel/package`,
  CREATE_PACKAGE_ORDER: 'https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travel/order',
  GET_ALL_ORDERS_BY_USER: 'https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travel/order/byId',
  GET_ORDER_BY_ID: 'https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travel/order',
  SEND_EMAIL: 'http://65.0.10.6:8011/sns-service/email/send',
  GET_CITY_BY_ID: `https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travel/city`,
  CREATE_PACKAGE: 'https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travel/package',
  UPDATE_TRAVEL_ORDER: 'https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travel/order/status',
};

// export default {
//     GET_ALL_PACKAGES: `https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/travepackage/all/${TRAVEL_SHOP_ID}`,
//     GET_PACKAGE_BY_ID: `https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/package/${TRAVEL_SHOP_ID}`,
//     CREATE_PACKAGE_ORDER: 'https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/order',
//     GET_ALL_ORDERS_BY_USER: 'https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/order/byId',
//     GET_ORDER_BY_ID: 'https://kya7lw7ua5.execute-api.ap-south-1.amazonaws.com/travel-prod/order'
// }
