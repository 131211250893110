export default {
  cognito: {
    REGION: 'ap-south-1',
    USER_POOL_ID: 'ap-south-1_KT0IaNseT',
    APP_CLIENT_ID: '73jv4jcd4f9auur10f1anb5qrh',
    BUCKET: 'YOUR_BUCKET_NAME' /** We don't need, so we removed it. */,
    ACCESS_KEY: 'YOUR_COGNITO_ACCESS_KEY' /** We don't need, so we removed it. */,
    SECRET_KEY: 'YUR_COGNITO_SECRET_KEY' /** We don't need, so we removed it. */,
  },
};
