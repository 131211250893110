// ** AWS Imports
import { Auth } from 'aws-amplify';
// ** Axios Import
import axios from 'axios';
// ** Redux functions
import { login, setProfileData, setShopData } from '../redux/slices/auth';
// ** Import 'env' and GET, POST, PUT and DELETE methods
import { AWS_API_ENDPOINTS } from 'src/env';
import { env } from '../config/env/release';
import { getData, postData } from './requests';

const { COMMON_ENDPOINTS, SHOP_ENDPOINTS } = env;

export const signIn = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password);
    return user;
  } catch (error) {
    return false;
  }
};

export const signUpUser = async ({ payload }) => {
  try {
    const res = await axios.post(AWS_API_ENDPOINTS.user.CREATE_NEW_USER, payload);
    return res;
  } catch (e) {
    return { message: e.response.data.message, status: e.response.status };
  }
};

export const handleUserSignIn = async (userData, dispatch, navigate) => {
  try {
    const email = userData?.idToken?.payload?.email;

    const accessToken = await Auth.currentSession()
      .then((session) => {
        return session.getIdToken().getJwtToken();
      })
      .catch((err) => {
        return false;
      });

    if (!accessToken) {
      throw new Error('Token not found');
    }

    dispatch(login(accessToken)); /** Set access token to redux */

    const dataFromDb = await getUserByEmail(email, accessToken);

    if (!dataFromDb) {
      throw new Error('User not found by email');
    }

    dispatch(setProfileData(dataFromDb)); /** Set user to redux */

    if (dataFromDb.userDetails.userType !== 'shop') {
      throw new Error("You're not shop owner");
    }

    let shopDataFromDb = await getShopDataByUserId({
      userId: dataFromDb.userDetails.userId,
      accessToken,
    });

    dispatch(setShopData(shopDataFromDb?.[0] || null)); /** Set shop to Redux */

    return shopDataFromDb?.[0];
  } catch (error) {}
};

export const getUserByEmail = async (email, accessToken) => {
  try {
    const response = await getData(`${AWS_API_ENDPOINTS.user.GET_USER_BY_EMAIL}/${email}`, accessToken);
    return response;
  } catch (e) {
    return;
  }
};

export const getShopDataByUserId = async ({ userId, accessToken }) => {
  const res = await getData(`${AWS_API_ENDPOINTS.shop.GET_SHOP_BY_USER_ID}/${userId}`, accessToken);
  return res || false;
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    return await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        return await Auth.changePassword(user, oldPassword, newPassword);
      })
      .catch((err) => {
        return { err: 'Current password provided is incorrect' };
      });
  } catch (error) {
    throw error;
  }
};

export const resendOtp = async ({ phone, email }) => {
  let _phone;
  let _email;

  // ** For Phone Number
  if (phone) {
    if (phone.includes('+91')) {
      _phone = phone;
    } else {
      _phone = `+91${phone}`;
    }
  }

  // ** For Email Address
  if (email) {
    _email = email;
  }

  try {
    const payload = {
      mobileNumber: _phone || '',
      emailAddress: _email || '',
    };

    const response = await postData(AWS_API_ENDPOINTS.user.REQUEST_NEW__OTP, '', payload);

    if (response.status === 'Success') {
      return response.details;
    } else {
      throw new Error('Otp not sent');
    }
  } catch (error) {
    return false;
  }
};

export const verifyOtp = async (mobileNumber, otp, sessionId) => {
  const url = `${COMMON_ENDPOINTS.VERIFY_OTP}`;
  let phone;
  // console.log('mobileNumber::', mobileNumber);

  if (mobileNumber.includes('91') && mobileNumber.length >= 12) {
    phone = `+${mobileNumber}`;
  } else {
    phone = `+91${mobileNumber}`;
  }

  try {
    const response = await axios.post(AWS_API_ENDPOINTS.user.VERIFY_OTP, {
      mobileNumber: phone,
      otp,
      sessionId,
    });

    if (response.data?.status === 'Success') {
      return response.data.details;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const handleForgetPassword = async ({ phoneNumber, emailAddress, otp, newPassword, sessionId }) => {
  try {
    const payload = {
      // ...(phoneNumber ? { phoneNumber: `+ 91${ phoneNumber } ` } : {}),
      // ...(emailAddress ? { emailAddress: emailAddress } : {}),
      mobileNumber: phoneNumber ? `+ 91${phoneNumber} ` : '',
      emailAddress: emailAddress ? emailAddress : '',
      otp,
      password: newPassword,
      sessionId,
    };

    const response = await postData(AWS_API_ENDPOINTS.user.VALIDATE_OTP_AND_CHANGE_PASSWORD, '', payload);

    if (response) {
      return response;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const getPlanList = async () => {
  try {
    const response = await getData(AWS_API_ENDPOINTS.subscription.GET_ALL_PLANS);

    if (response) {
      return response;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const orderSelectedNewPlan = async (payload) => {
  try {
    const response = await postData(AWS_API_ENDPOINTS.subscription.ORDER_SELECTED_NEW_PLAN, '', payload);

    if (response) {
      return response;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
