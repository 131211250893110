// routes
import ROUTES_PATH, { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Travel Agent - Join Us',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: ROUTES_PATH.dashboard.root,
  },
  {
    title: 'About Us',
    icon: <Iconify icon={'mdi:about'} {...ICON_SIZE} />,
    path: ROUTES_PATH.dashboard.about,
  },
  {
    title: 'Contact Us',
    icon: <Iconify icon={'mdi:contact'} {...ICON_SIZE} />,
    path: ROUTES_PATH.dashboard.contact,
  },
];

export default menuConfig;
