import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, Box } from '@mui/material';
// routes
import ROUTES_PATH, { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Atomtrip',
    children: [
      { name: 'About us', href: ROUTES_PATH.dashboard.about },
      { name: 'Contact us', href: ROUTES_PATH.dashboard.contact },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: ROUTES_PATH.dashboard.termsOfService },
      { name: 'Privacy Policy', href: ROUTES_PATH.dashboard.privacyPolicy },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@atomtrip.com', href: '' },
      { name: 'H.O. - Abhee Prakruthi , Chandapura Bangalore 560099', href: '' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
          gap={4}
        >
          {/* <Grid item xs={12} sx={{ mb: 3 }}>
            <Box sx={{ display: 'inline-block' }}>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </Box>
          </Grid> */}
          <Grid item xs={12} md={3}>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' } }}>
                <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
              </Box>
              <Stack direction="row" justifyContent={{ xs: 'center', md: 'flex-start' }}>
                <SocialsButton sx={{ mx: 0.5 }} />
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={link.href ? RouterLink : 'span'}
                      sx={{
                        display: 'block',
                        ...(!link.href && {
                          cursor: 'default',
                          '&:hover': {
                            textDecoration: 'none',
                          },
                        }),
                      }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Box
          sx={{
            py: 2,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
            display: 'flex',
            fontSize: '14px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography color={'primary'} component={'span'} fontSize={'14px'}>
            @ 2024 Powered by Devapp Digital
          </Typography>
          , All rights reserved.
        </Box>
      </Container>
    </RootStyle>
  );
}
