/* eslint-disable no-else-return */
import axios from 'axios';

export const getData = async (url, accessToken, query) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
      params: {
        ...query,
      },
    });

    if (response?.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const postData = async (url, accessToken, payload) => {
  try {
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response?.status === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const putData = async (url, accessToken, payload) => {
  try {
    const response = await axios.put(url, payload, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response.status === 200 ? response.data : false;
  } catch (e) {
    return false;
  }
};

export const deleteData = async (url, accessToken) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response.status === 200 ? response.data : false;
  } catch (e) {
    return false;
  }
};
