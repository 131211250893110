import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/auth';
import { subscriptionSlice } from './slices/subscription';

export const authStore = configureStore({
  reducer: {
    authReducer: authSlice.reducer,
    subscriptionReducer: subscriptionSlice.reducer,
  },
});
