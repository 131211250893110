// ** React and Prototype Imports
import PropTypes from 'prop-types';
import { useState } from 'react';
// ** React Router Dom Import
import { Navigate, useLocation } from 'react-router-dom';
// ** Hooks
import useAuth from 'src/hooks/useAuth';
// ** Routes Import
import ROUTES_PATH from 'src/routes/paths';
// ** Loading screen: This screen take full height and width of body
import LoadingScreen from 'src/components/LoadingScreen';
import { useSelector } from 'react-redux';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  // ** Hooks
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const { userInfoLoading, shopInfoLoading } = useSelector((state) => state.authReducer);

  // ** States
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized || (isInitialized && isAuthenticated && (userInfoLoading || shopInfoLoading))) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Navigate to={ROUTES_PATH.auth.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
