import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: '',
    userInfoLoading: true,
    userInfo: null,
    shopInfoLoading: true,
    shopInfo: null,
    shopAddress: null,
  },
  reducers: {
    login: (state, action) => {
      state.accessToken = action.payload;
    },
    logout: (state) => {
      state.accessToken = '';
    },
    setProfileData: (state, action) => {
      state.userInfoLoading = false;
      state.userInfo = action.payload || null;
    },
    setShopData: (state, action) => {
      state.shopInfoLoading = false;
      state.shopInfo = action.payload || null;
    },
    setShopAddress: (state, action) => {
      state.shopAddress = action.payload || null;
    },
  },
});

export const { login, logout, setProfileData, setShopData, setShopAddress } = authSlice.actions;
