
import COMMON_ENDPOINTS from './common-endpoints';
import SHOP_ENDPOINTS from './shop-endpoints';
import CUSTOMER_ENDPOINTS from './endpoints';
import REVIEW_ENDPOINTS from './review-endpoints';
import TRAVEL_ENDPOINTS from './travel-endpoints';
import AWS_CONFIG from './aws-config';

export const env = {
    COMMON_ENDPOINTS,
    SHOP_ENDPOINTS,
    CUSTOMER_ENDPOINTS,
    REVIEW_ENDPOINTS,
    AWS_CONFIG,
    TRAVEL_ENDPOINTS
}