
export default {
 
    CREATE_NEW_USER_DB: `https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/signup`,
    GET_USER_BY_ID_DB: `https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user`,
    GET_USER_BY_EMAIL_DB: `https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/byemail`,
    GET_GEO_CODE: `https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/getGeoCode`,
    UPDATE_ZIP_CODE: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/updtzip',
    UPDATE_PUSH_TOKEN:'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/pushtoken',
    ADD_NEW_ADDRESS:'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/address',
    SEND_EMAIL:'https://1xh3ufjmq5.execute-api.ap-south-1.amazonaws.com/sns-prod/email/send',
    GET_USER_BY_PHONE_NUMBER: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/getUser',
    UPDATE_USER_PHONE_NUMBER: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/updtcontact',
    REQUEST_OTP: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/generateotp',
    VERIFY_OTP:'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/verifyotp',
    FORGOT_PASSWORD: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/forgotPassword',
    GET_NOTIFICATIONS: 'https://zipin-services.firebaseio.com/notifications',
    DELETE_ADDRESS:'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/address/delete',
    UPDATE_PRIMARY_ADDRESS:'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/address/updtPrimary',
    GET_ALL_ADDRESSES: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/address',
    GET_REFERRAL_INFO: 'https://9lugs1b10b.execute-api.ap-south-1.amazonaws.com/user-prod/user/referUs',
    ADD_UPDATE_PRODUCT: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products',
    DELETE_PRODUCT: 'https://ya21cw7j44.execute-api.ap-south-1.amazonaws.com/product-prod/products/deleteProduct',
}