// ** React and Prototype Imports
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// ** Hooks
import useAuth from 'src/hooks/useAuth';
// ** Routes path Imports
import ROUTES_PATH from '../routes/paths';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={ROUTES_PATH.dashboard.root} />;
  }

  return <>{children}</>;
}
